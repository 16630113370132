<template lang="pug">
div(:class="uploader.classes")
  //- 遍历转换后的 data
  template(v-if="values && values.length")
    .vc-uploaded(:style="preview.size" v-for="(item, index) in values" :key="item.id")
      .vc-upload-container
        template(v-if="item.mime")
        img.full-width(:src="item.src")
        a-badge(color="blue" style="position: absolute; bottom: 1px; right: 1px;" v-if="item.tiia")
        .upload-cover-actions
          a-icon(type="eye" size="28" @click.native="handlePreview(item)")
          a-icon(type="delete" size="28" @click.native="handleRemove(item)")

  //- 遍历上传时的 fileList
  template(v-if="fileList.length")
    .vc-uploaded(:style="preview.size" v-if="item.status !== 'done'" v-for="(item, index) in fileList" :key="index")
      .vc-upload-container
        template(v-if="item.status === 'uploading'")
          span 文件上传中
          a-progress(:percent="item.percent" size="small")
        template(v-if="item.status === 'error'")
          span 文件上传错误
          a-progress(:percent="item.percent" size="small" status="exception")

  .vc-uploader(v-show="uploader_shown")
    a-upload-dragger(
      :style="preview.size"

      :multiple="uploader.multiple"
      :headers="uploader.headers"
      :action="uploader.action"
      :accept="uploader.accept"
      :data="uploader.data"

      :show-upload-list="uploader.listShow"
      :list-type="uploader.listType"

      :transform-file="transformFile"
      :before-upload="beforeUpload"
      @change="handleChange")
      
      a-icon(:type="preview.icon.type" :style="{ color: preview.icon.color, fontSize: preview.icon.size }")
      p {{ preview.label }}
  
  //- a-divider(style="font-size:11px;") options
  //- pre(style="font-size:11px;line-height:1;") {{ options }}
  
  //- a-divider(style="font-size:11px;") data
  //- pre(style="font-size:11px;line-height:1;") {{ data }}
  
  //- a-divider(style="font-size:11px;") values
  //- pre(style="font-size:11px;line-height:1;") {{ values }}
  
  //- a-divider(style="font-size:11px;") fileList
  //- pre(style="font-size:11px;line-height:1;") {{ fileList }}
</template>

<script>
import mime from '@/config/mime'
import vcAttachmentView from '@/components/vcAttachmentView'

//- function getBase64(file) {
//-   return new Promise((resolve, reject) => {
//-     const reader = new FileReader();
//-     reader.readAsDataURL(file);
//-     reader.onload = () => resolve(reader.result);
//-     reader.onerror = error => reject(error);
//-   });
//- }

// 常用图片的三种格式 
// 'image/*'; - 全部图片格式
const default_accept = ['.jpg', '.jpeg', '.png' ].join(', ');
const default_maxsize = 2 * 1024 * 1024;

function num2unit(num, unit = 'px') {
  if (num.toString().indexOf('%') > -1) return num;
  return `${num}${unit}`;
}

export default {
  name: 'vcUploader',
  component: {},
  props: {
    data: {
      type: [
        Object, // 单个
        Array   // 多个
      ]
    },
    options: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  computed: {
    uploader_shown() {
      const { options, values } = this;

      let shown = true;
      if (!options.maxfile) {
        if (values.length) shown = false;
      } else {
        if (values.length === options.maxfile) shown = false;
      }
      return shown;
    },
    preview() {
      const { preview, maxfile } = this.options;
      
      let label = '文件上传';
      let icon = {
        type: 'camera',
        color: '#cdcdcd',
        size: '28px'
      };
      let size = { display: 'table' };

      if (preview && preview.label) label = preview.label;
      if (preview && preview.icon) icon = Object.assign(icon, preview.icon);
      if (preview && preview.size && Array.isArray(preview.size)) {
        size.width = num2unit(preview.size[0]);
        size.height = num2unit(preview.size[1]);
      } else if (maxfile && maxfile !== 1) {
        size.width = size.height = num2unit(85);
      } else {
        size.width = size.height = '100%';
      }
      
      return { icon, label, size };
    },
    uploader() {
      const { options } = this;
      return {
        classes: [ `vc-upload-${!options.maxfile || options.maxfile === 1 ? 'single' : 'multiple'}` ],
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        action: `${this.$http.defaults.baseURL}attachment`,
        accept: options.accept ? options.accept.join(', ') : default_accept,
        data: options.extra_data || { app: 'unknow', module: 'unknow' },
        maxsize: options.maxsize || default_maxsize,
        multiple: !options.maxfile || options.maxfile === 1 ? false : options.multiple,
        
        // 可作为上传调试用
        listShow: false,
        listType: 'picture-card'
      }
    },
    values() {
      if (!this.data) return [];
      return Array.isArray(this.data) ? this.data : [ this.data ];
      //- return !this.options.maxfile || this.options.maxfile === 1 ? [ this.data ] : this.data;
    }
  },
  methods: {
    /*********************************************************
     * @description 在上传之前转换文件。例如加水印等
     * @param {File} file 上传文件
     *********************************************************/
    transformFile(file) {
      return file;
      //- return new Promise(resolve => {
      //-   const reader = new FileReader();
      //-   reader.readAsDataURL(file);
      //-   reader.onload = () => {
      //-     const canvas = document.createElement('canvas');
      //-     const img = document.createElement('img');
      //-     img.src = reader.result;
      //-     img.onload = () => {
      //-       const ctx = canvas.getContext('2d');
      //-       ctx.drawImage(img, 0, 0);
      //-       ctx.fillStyle = 'red';
      //-       ctx.textBaseline = 'middle';
      //-       ctx.fillText('Ant Design', 20, 20);
      //-       canvas.toBlob(resolve);
      //-     };
      //-   };
      //- });
    },
    
    /*********************************************************
     * @description 上传文件之前的钩子
     * @param {File} file 上传文件
     *********************************************************/
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const { type, size } = file;
        const { accept, maxsize } = this.uploader;

        //- console.log('beforeUpload::Promise', { type, size, accept, maxsize })
        
        //- 文件类型、后缀判断
        let is_type_require = false;
        if (accept.indexOf('*') !== -1) {
          is_type_require = type.split('/')[0] === accept.split('/')[0];
        } else {
          const _exts = Object.keys(mime).map(item => `.${item}`);
          const _mimes = Object.values(mime);

          const _accept_exts = accept.split(', ').filter(item => _exts.includes(item));
          const _accept_mimes = accept.split(', ').filter(item => _mimes.includes(item));
          
          //- console.log(_accept_exts, _accept_mimes)
          
          if (_accept_mimes.length) {
            is_type_require = _accept_mimes.includes(type);
          }
          if (_accept_exts.length) {
            const file_ext = file.name.split('.').pop();
            is_type_require = _accept_exts.includes(`.${file_ext}`);
          }
        }
        if (!is_type_require) {
          this.$notification.error({
            message: '文件格式不正确',
            description: `${file.name} 格式不正确，请选择 ${this.uploader.accept.split(',').join(' 或 ')}`
          });
        }
        
        //- 文件大小判断
        const is_size_require = maxsize >= size;
        if (!is_size_require) {
          this.$notification.error({
            message: '文件大小超出限制',
            description: `${file.name} 太大，不得超过 ${this.uploader.maxsize} kb`
          });
        }
        
        if (is_type_require && is_size_require) {
          resolve();
        } else {
          reject({
            type: is_type_require,
            size: is_size_require
          });
        }
        //- return is_type_require && is_size_require;
      });
    },
    
    /*********************************************************
     * @description 上传文件改变时的状态
     * @param { event:ProgressEvent, file:{}, fileList:[] } info 上传的文件及状态
     *********************************************************/
    handleChange(info) {
      const { file, fileList } = info;

      if (file.status === 'uploading') {
        this.fileList = fileList;
      } else if (file.status === 'error') {
        return this.$message.error('上传失败');
      } else if (file.status === 'done') {
        console.log(this.data)
        
        //- if (this.data) {
        //-   if (!this.options.maxfile || this.options.maxfile === 1) {
        //-     this.data = file.response
        //-   } else {
        //-     this.data.push(file.response);
        //-   }
        //- } else {
          if (!this.options.maxfile || this.options.maxfile === 1) {
            this.$emit('success', this.options.key, file.response);
          } else {
            this.values.push(file.response);
            this.$emit('success', this.options.key, this.values);
          }
        //- }
      }
    },

    /*********************************************************
     * @description 附件操作 查看/删除
     *********************************************************/
    // 查看附件
    handlePreview(file) {
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
      // }
      // this.previewImage = file.url || file.preview;

      this.$dialog(vcAttachmentView, {
        data: file
      }, {
        title: file.name,
        footer: () => null
      });
    },

    // 删除附件
    handleRemove(file) {
      this.$http.delete(`attachment/${file.id}`).then(() => {
        if (!this.options.maxfile || this.options.maxfile === 1) {
          this.$emit('success', this.options.key, null);
        } else {
          this.values.splice(this.values.indexOf(file), 1);
          this.$emit('success', this.options.key, this.values);
        }
      }).catch(err => {
        this.$message.error(err.message);
      });
    },
  },
  created() {
    //- console.log(this.data)
    //- console.log(this.options)
  }
}
</script>

<style lang="less">

.vc-upload-multiple {
  line-height: 1;
  .vc-uploaded,
  .vc-uploader {
    float: left;
    margin: 0 12px 12px 0;
  }
}
.vc-upload-container {
  background: #eee;
  position: relative;
  cursor: pointer;
  width: 100%; height: 100%;
  display: table-cell;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 0.3s;

  &::before {
    content: " ";
    display: block;
    position: absolute; z-index: 1;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: all .3s;
  }
  
  .upload-cover-actions {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    opacity: 0;

    .anticon {
      color: #fff;
      font-size: 18px;
      margin: 0 3px;
    }
  }
  &:hover {
    border-color: #40a9ff;

    &::before { opacity: 1; }
    .upload-cover-actions { opacity: 1; }
  }
}

.a {
  .upload-single {
    text-align: center;
    overflow:hidden;
    position:relative;

    img { max-width: 100%;}
    
    &:hover .upload-cover {
      display: block;
    }
  }

  .upload-list {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    margin-right: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .upload-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
  }
  .upload-list:hover .upload-cover{
    display: block;
  }

  .upload-cover {
    // display: flex; justify-content: center; align-items: center;
    i {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      margin: 0 2px;
    }
  }

  .ivu-upload-list { display: none;}
  .upload-multiple {
    display: flex; flex-wrap: wrap;
    .upload-wrap {
      width: 33.33%; padding: 5px;
    }
    .vc-uploader {
      width: 100%; margin-top: 15px;
    }
  }
}
</style>