'strict'

export default {
  // image
  gif   : 'image/gif',
  png   : 'image/png',
  apng  : 'image/apng',
  jpg   : 'image/jpeg',
  jpeg  : 'image/jpeg',
  pjpeg : 'image/pjpeg',
  bmp   : 'image/bmp',
  tiff  : 'image/tiff',
  webp  : 'image/webp',
  svg   : 'image/svg+xml',
  icon  : 'image/x-icon',

  // doc,
  // docx,
}