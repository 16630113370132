<template lang="pug">
  img.full-width(:src="data.src || data.guid" v-if="data")
</template>

<script>
export default {
  name: 'vcAttachmentView',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
}
</script>